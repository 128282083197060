import React from 'react';
import DownloadDropdownButton from './DownloadDropdownButton';
import './DesktopAppPage.css';

const DesktopAppPage = () => {
  return (
    <div className="app-div">
      <div className="app-text-container">
        <img
          alt="desktop app download icon"
          src="/assets/images/appDownload.svg"
        />
        <h1 className="app-h1 app-text">Download Electric</h1>
        <p className="app-p download-app-text">
          Download Electric now to optimize your device's performance and
          health.
          <br />
          Our app securely reads diagnostics like CPU usage, memory status, and
          storage health, giving you actionable insights to enhance efficiency
          and fix issues.
          <br />
          <br />
          And don't worry, we prioritize your privacy by accessing only device
          analytics. Stay informed and keep your laptop running smoothly with
          Electric.
        </p>
        <DownloadDropdownButton />
      </div>
    </div>
  );
};

export default DesktopAppPage;
